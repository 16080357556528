<template>
  <el-container>
    <el-aside width="300" v-show="!loginMode">
      <div class="left-head">
        <div class="head-top">
          <img src="./assets/img/logo.png" alt=""/>
          <p>{{ name }}</p>
        </div>
      </div>
      <side-menus></side-menus>
    </el-aside>
    <el-container v-bind:class="{
                'main-container': true,
                'side-menu-collapsed': $store.state.common.sideMenuCollapsed,
                'side-menu-none': loginMode
            }"
    >
      <el-header v-show="!loginMode" v-bind:class="{
                'main-header': true,
                'side-menu-collapsed': $store.state.common.sideMenuCollapsed,
                'side-menu-none': loginMode
            }"
      >
        <main-header></main-header>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import SideMenus from '@/components/common/SideMenus'
import MainHeader from '@/components/common/MainHeader'
import { region } from '@/apis/common'

export default {
  components: {
    MainHeader,
    SideMenus
  },
  data() {
    return {
      name: '',
      totalAdmin: [
        {
          id: 2,
          menuIcon: require('@/assets/img/icon/xitong.png'),
          menuName: '系统设置',
          menuParent: 0,
          menuPath: ''
        },
        {
          id: 6,
          menuIcon: require('@/assets/img/icon/jigou.png'),
          menuName: '机构管理',
          menuParent: 2,
          menuPath: '/system/mechanism'
        },
        {
          id: 7,
          menuIcon: require('@/assets/img/icon/gonggao.png'),
          menuName: '设置公告',
          menuParent: 2,
          menuPath: '/system/announcement'
        },
        {
          id: 8,
          menuIcon: require('@/assets/img/icon/zhuanye.png'),
          menuName: '专业管理',
          menuParent: 2,
          menuPath: '/system/major'
        }
      ],
      mechAdmin: [
        {
          id: 2,
          menuIcon: require('@/assets/img/icon/xitong.png'),
          menuName: '系统管理',
          menuParent: 0,
          menuPath: ''
        },
        {
          id: 5,
          menuIcon: require('@/assets/img/icon/fenlei.png'),
          menuName: '学校分类',
          menuParent: 2,
          menuPath: '/mech/classify'
        },
        {
          id: 6,
          menuIcon: require('@/assets/img/icon/xuexiao.png'),
          menuName: '学校管理',
          menuParent: 2,
          menuPath: '/mech/school'
        },
        {
          id: 6,
          menuIcon: require('@/assets/img/icon/kecheng.png'),
          menuName: '课程管理',
          menuParent: 2,
          menuPath: '/mech/curriculum'
        },
        {
          id: 7,
          menuIcon: require('@/assets/img/icon/kecheng.png'),
          menuName: '课程套餐',
          menuParent: 2,
          menuPath: '/mech/package'
        }
      ],
      schoolAdmin: [
        {
          id: 1,
          menuIcon: require('@/assets/img/icon/jiaowu.png'),
          menuName: '教务管理',
          menuParent: 0,
          menuPath: ''
        },
        {
          id: 13,
          menuIcon: require('@/assets/img/icon/banji.png'),
          menuName: '班级管理',
          menuParent: 1,
          menuPath: '/school/class'
        },
        {
          id: 12,
          menuIcon: require('@/assets/img/icon/xueshengzhanghao.png'),
          menuName: '学生账号',
          menuParent: 1,
          menuPath: '/school/student'
        },
        {
          id: 11,
          menuIcon: require('@/assets/img/icon/jiaoshi.png'),
          menuName: '教师账号',
          menuParent: 1,
          menuPath: '/school/teacher'
        },
        {
          id: 14,
          menuIcon: require('@/assets/img/icon/paike.png'),
          menuName: '排课课表',
          menuParent: 1,
          menuPath: '/school/schedule'
        },
        {
          id: 2,
          menuIcon: require('@/assets/img/icon/caiwu.png'),
          menuName: '财务管理',
          menuParent: 0,
          menuPath: ''
        },
        {
          id: 22,
          menuIcon: require('@/assets/img/icon/keshi.png'),
          menuName: '课时缴费',
          menuParent: 2,
          menuPath: '/school/lesson'
        },{
          id: 21,
          menuIcon: require('@/assets/img/icon/keshi.png'),
          menuName: '账户充值',
          menuParent: 2,
          menuPath: '/school/recharge'
        },{
          id: 23,
          menuIcon: require('@/assets/img/icon/zhuanye.png'),
          menuName: '明细查询',
          menuParent: 2,
          menuPath: '/school/detail'
        },{
          id: 24,
          menuIcon: require('@/assets/img/icon/shouzhi.png'),
          menuName: '收支查询',
          menuParent: 2,
          menuPath: '/school/income'
        },{
          id: 3,
          menuIcon: require('@/assets/img/icon/kuchun.png'),
          menuName: '库存管理',
          menuParent: 0,
          menuPath: ''
        },{
          id: 31,
          menuIcon: require('@/assets/img/icon/taochan.png'),
          menuName: '商品分类',
          menuParent: 3,
          menuPath: '/school/product'
        },{
          id: 32,
          menuIcon: require('@/assets/img/icon/chuku.png'),
          menuName: '商品管理',
          menuParent: 3,
          menuPath: '/school/management'
        },{
          id: 33,
          menuIcon: require('@/assets/img/icon/chuku.png'),
          menuName: '入库管理',
          menuParent: 3,
          menuPath: '/school/warehousing'
        },{
          id: 34,
          menuIcon: require('@/assets/img/icon/chuku.png'),
          menuName: '出库管理',
          menuParent: 3,
          menuPath: '/school/outbound'
        }
      ]
    }
  },
  watch: {
    $route() {
      const user = JSON.parse(sessionStorage.getItem('user'))
      if (user) {
        this.name = user.agency_school_name
        if(user.user_type === 0) this.$store.commit('user/updateMenus', this.totalAdmin)
        if(user.user_type === 1) this.$store.commit('user/updateMenus', this.mechAdmin)
        if(user.user_type === 2) this.$store.commit('user/updateMenus', this.schoolAdmin)
      }
      this.getRegion()
    }
  },
  computed: {
    loginMode() {
      return this.$route && this.$route.path.indexOf('/login') >= 0
    }
  },
  methods: {
    getRegion() {
      region().then(res => {
        this.$store.commit('setAddress', res.data.data)
      })
    }
  }
}
</script>

<style scoped>
.left-head {
    background: linear-gradient(0deg, #8BBAF4, #8FBBF5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 300px;
}

.head-top {
    height: 210px;
    width: 100%;
    text-align: center;
    background: linear-gradient(90deg, #71A3F9, #B4ECE3);
}

.head-top > img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    padding-top: 12px;
}

.head-top > p {
    font-size: 34px;
    color: #fff;
    margin: 0;
}

.main-container {
    margin-left: 300px;
    padding-top: 60px;
    position: relative;
}

.main-container.side-menu-collapsed {
    margin-left: 65px;
}

.main-container.side-menu-none {
    margin-left: 0px;
}

.main-header {
    position: fixed;
    top: 0;
    left: 300px;
    width: 100%;
    padding-right: 324px;
    box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;
    background-color: white;
    z-index: 10;
}

.main-header.side-menu-collapsed {
    left: 65px;
    padding-right: 89px;
}

.main-header.side-menu-none {
    left: 0px;
    padding-right: 0px;
}
</style>

