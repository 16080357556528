const LTT = require('list-to-tree')

export let getTree = (list, parentKey) => {
    const ltt = new LTT(list, {
        key_id: 'id',
        key_parent: parentKey || 'menuParent'
    })

    ltt.sort((a, b) => {
        return a.content.orderNum - b.content.orderNum
    })

    return ltt.GetTree()
}

export let convertTreeToList = (root) => {
    var stack = [], array = [], hashMap = {};
    stack.push(root);

    const visitNode = (n, m, a) => {
        if(!m[n.id]) {
            m[node.id] = true;
            a.push(n);
        }
    }
    
    while(stack.length !== 0) {
        var node = stack.pop();
        
        if(!node.children || node.children.length == 0) {
            visitNode(node, hashMap, array);
        } else {
            if (node.id) {
                stack.push({
                    id: node.id,
                    value: node.value
                })
            }

            for(var i = node.children.length - 1; i >= 0; i--) {
                stack.push(node.children[i]);
            }
        }
    }

    return array;
}