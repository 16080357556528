<template>
    <el-row justify="space-between" align="middle" style="padding-top: 16px;">
        <el-row justify="start" align="middle">
<!--            <el-tooltip-->
<!--                effect="dark"-->
<!--                :content="$store.state.common.sideMenuCollapsed"-->
<!--                placement="bottom-start"-->
<!--                >-->
<!--                <i v-bind:class="$store.state.common.sideMenuCollapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"-->
<!--                    @click="toggleSideMenuState" class="side-menu-trigger"></i>-->
<!--            </el-tooltip>-->

            <el-breadcrumb style="margin-left: 16px;" separator="/" v-if="navItems && navItems.length > 0">
                <el-breadcrumb-item :to="item" :replace="true" v-for="(item, index) in navItems" :key="'breadcrumb_' + index">
                    {{ item.humanName }}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </el-row>

        <el-row justify="end" align="middle">
            <div class="home" @click="homeTo">
                <img src="../../assets/img/shouye.png" alt="" />
            </div>
            <el-dropdown>
                <div class="avatar-cont">
                    <img v-if="info.avatar" class="avatar" :src="info.avatar" alt="" />
                    <img v-else class="avatar" src="../../assets/img/touxiang.png" alt="" />
                    <span class="username-dropdown-link">
                    {{ info.name }} <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                </div>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-row>
    </el-row>
</template>
<script>

export default {
    name: 'MainHeader',
    data() {
        return {
            info: JSON.parse(sessionStorage.getItem('user')) || {}
        }
    },
    computed: {
        navItems() {
            if (this.$route && this.$route.matched && this.$route.matched.length) {
                return this.$route.matched.map(item => {
                        return {
                            path: item.path == this.$route.fullPath ? '' : item.path,
                            humanName: item.meta ? item.meta.humanName : item.name,
                        }
                    }).filter(item => !!item.humanName)
            }
            return '未知'
        },
    },
    mounted() {

    },
    methods: {
        homeTo() {
            this.$router.push({path: '/'})
        },
        toggleSideMenuState() {
            this.$store.commit('common/toggleSideMenuCollapsed');
        },
        logout() {
            this.$store.commit('user/updateMenus', [])
            sessionStorage.removeItem('user')
            this.$router.replace({path: '/login'})
        }
    }
}
</script>
<style scoped>
.home{
    margin-right: 40px;
}
.home>img{
    width: 22px;
    height: 22px;
    cursor: pointer;
    display: block;
}
.avatar-cont{
    display: flex;
    align-items: center;
}
.avatar{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
}
.side-menu-trigger {
    cursor: pointer;
    font-size: 24px;
}

.username-dropdown-link {
  cursor: pointer;
  color: #409eff;
  padding: 0px 10px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
