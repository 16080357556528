export default {
  path: '/mech',
  component: () => import(/* webpackChunkName: "systemIndex" */ '../../views/mechSys/Index.vue'),
  meta: {
    humanName: '系统管理'
  },
  children: [{
    path: '/mech/classify',
    name: '学校分类',
    component: () => import('../../views/mechSys/classify/classify.vue'),
    meta: { humanName: '学校分类' }
  },{
    path: '/mech/school',
    name: '学校管理',
    component: () => import('../../views/mechSys/school/school.vue'),
    meta: { humanName: '学校管理' }
  },{
    path: '/mech/curriculum',
    name: '课程管理',
    component: () => import('../../views/mechSys/curriculum/curriculum.vue'),
    meta: { humanName: '课程管理' }
  },{
    path: '/mech/package',
    name: '课程套餐',
    component: () => import('../../views/mechSys/package/package.vue'),
    meta: { humanName: '课程套餐' }
  }]
}
