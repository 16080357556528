import common from './modules/common'
import user from './modules/user'
import { createStore } from 'vuex'

export default createStore({
  state: {
    address:[]
  },
  mutations: {
    setAddress(state,payload) {
      state.address = payload
    }
  },
  actions: {
  },
  modules: {
    common,
    user
  }
})
