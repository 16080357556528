export default {
    path: '/system',
    component: () => import(/* webpackChunkName: "systemIndex" */ '../../views/system/Index.vue'),
    meta: {
        humanName: '系统设置'
    },
    children: [{
      path: '/system/mechanism',
      name: '机构管理',
      component: () => import('../../views/system/mechanism/mechanism.vue'),
      meta: { humanName: '机构管理' }
    },{
      path: '/system/announcement',
      name: '设置公告',
      component: () => import('../../views/system/announcement/announcement.vue'),
      meta: { humanName: '设置公告' }
    },{
      path: '/system/major',
      name: '专业管理',
      component: () => import('../../views/system/major/major.vue'),
      meta: { humanName: '专业管理' }
    }]
}
