import { getTree } from '@/utils/menu'

export default {
    namespaced: true,
    state: {
        realName: '',
        phone: '',
        clientRole: '',
        permCodes: [],
        menus: [],
        permMap: []
    },
    mutations: {
      updateUserInfo(state, payload) {
          state.realName = payload.realName
          state.phone = payload.phone
          state.clientRole = payload.clientRole
          state.permMap = payload.permMap
      },
      updateMenus(state, payload) {
        state.menus = getTree(payload || []) || []
      },
      updatePerms(state, payload) {
        state.permCodes = payload
      }
    },
    actions: {
      
    },
    getters: {

    }
  }