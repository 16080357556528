export default {
  namespaced: true,
  state: {
    redirectURLCookieKey: '_deals_redirect_url',
    tokenCookieKey: '_deals_token',
    localeCookieKey: '_deals_locale',
    userinfoStorageKey: 'userinfo',
    // 主界面导航栏是否收起
    sideMenuCollapsed: false, 
    statusActive: 'active',
    statusInactive: 'inactive',
    yesValue: 'y',
    noValue: 'n',
  },
  mutations: {
    toggleSideMenuCollapsed(state) {
      state.sideMenuCollapsed = !state.sideMenuCollapsed
    }
  },
  actions: {
    
  },
  getters: {
    
  }
}
