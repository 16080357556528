import { createRouter, createWebHistory } from 'vue-router'
import system from './modules/system'
import mech from './modules/mech'
import school from './modules/school'

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {humanName: '首页'}
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {humanName: '登录'}
  },
  system,
  mech,
  school,
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    meta: {humanName: '404'}
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from, failure) => {
  if (!to.matched || to.matched.length == 0) {
    router.replace({ path: '/404' })
  } else {
    if (to.fullPath.indexOf('/login') < 0) {
      if (!sessionStorage.getItem('user')) {
        router.replace({ path: '/login' })
      }
    }
  }
})

export default router
