import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import Vue3Storage from "vue3-storage"
import { StorageType } from 'vue3-storage'
import moment from 'moment'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import 'element-plus/dist/index.css'
import '@/assets/css/base.css'

const app = createApp(App)

app.config.globalProperties.$moment = moment

app
    .use(store)
    .use(router)
    .use(ElementPlus, { size: 'small', zIndex: 3000, locale: zhCn })
    .use(VueCookieNext)
    .use(Vue3Storage, { namespace: "deals_", storage: StorageType.Local })

VueCookieNext.config({ expire: '7d' })

app.mount('#app')
