import axios from 'axios'
import {VueCookieNext} from 'vue-cookie-next'
import { ElMessage } from 'element-plus'
import store from "../store";
import router from "../router";

VueCookieNext.config({expire: '2d'})
const _axios = axios.create({
  baseURL: '/'
});
_axios.interceptors.request.use(
  function (config) {
    const token = VueCookieNext.getCookie(store.state.token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
_axios.interceptors.response.use(
  function (response) {
    if (response.config.responseType === 'blob') return response;
    if (response.data.code && !response.data.msg) {
      ElMessage.error('未知错误')
      return Promise.reject(null)
    }
    if (response.data.status === 'success') {
      return response;
    } else {
      if(response.data.msg === 'Unauthenticated.') {
        ElMessage.error('登录过期')
        VueCookieNext.removeCookie(store.state.token)
        router.replace({ path: '/login' })
      }else {
        ElMessage.error(response.data.msg)
      }
      return Promise.reject(response.data)
    }
  },
  function (error) {
    return Promise.reject(null);
  }
);

export default _axios
