import request from './request'

export let login = (data) => {
    return request.post('/console/login', data)
}
export let adminInfo = (params) => {
    return request.get('/console/get-admin-info', {
        params: params
    })
}
export let agencyList = (params) => {
    return request.get('/console/agency-list', {
        params: params
    })
}
export let noticeList = (params) => {
    return request.get('/console/notice-list', {
        params: params
    })
}
export let agencySubmit = (data) => {
    return request.post('/console/agency-submit', data)
}
export let region = (params) => {
    return request.get('/console/get-region', {
        params: params
    })
}
export let updateStatus = (params) => {
    return request.get('/console/agency-update-status', {
        params: params
    })
}
export let agencyDel = (params) => {
    return request.delete('/console/agency-del', {
        params: params
    })
}
export let majorDel = (params) => {
    return request.delete('/console/major-del', {
        params: params
    })
}
export let schoolDelAdmin = (params) => {
    return request.delete('/console/school-del', {
        params: params
    })
}
export let schoolDel = (params) => {
    return request.delete('/console/school-type-del', {
        params: params
    })
}
export let packageDel = (params) => {
    return request.delete('/console/course-package-del', {
        params: params
    })
}
export let courseDel = (params) => {
    return request.delete('/console/course-del', {
        params: params
    })
}
export let schoolClassDel = (params) => {
    return request.delete('/console/school-class-del', {
        params: params
    })
}
export let schoolKindDel = (params) => {
    return request.delete('/console/school-money-kind-del', {
        params: params
    })
}
export let courseOrderDel = (params) => {
    return request.delete('/console/course-order-del', {
        params: params
    })
}
export let stockGoodsInboundDel = (params) => {
    return request.delete('/console/stock-goods-inbound-del', {
        params: params
    })
}
export let goodsTypeDel = (params) => {
    return request.delete('/console/stock-goods-type-del', {
        params: params
    })
}
export let stockGoodsDel = (params) => {
    return request.delete('/console/stock-goods-del', {
        params: params
    })
}
export let stockGoodsOutboundDel = (params) => {
    return request.delete('/console/stock-goods-outbound-del', {
        params: params
    })
}
export let timetableDel = (params) => {
    return request.get('/console/timetable-delete', {
        params: params
    })
}
export let userDel = (params) => {
    return request.delete('/console/user-del', {
        params: params
    })
}
export let schoolMoneyDel = (params) => {
    return request.delete('/console/school-money-del', {
        params: params
    })
}
export let userRechargeDel = (params) => {
    return request.delete('/console/user-recharge-del', {
        params: params
    })
}

export let homeStatistics = (params) => {
    return request.get('/console/home-statistics', {
        params: params
    })
}
export let homeTimetable = (params) => {
    return request.get('/console/home-timetable-statistics', {
        params: params
    })
}
export let noticeUpdateStatus = (params) => {
    return request.get('/console/notice-update-status', {
        params: params
    })
}
export let noticeSubmit = (data) => {
    return request.post('/console/notice-submit', data)
}
export let courseOrderSubmit = (data) => {
    return request.post('/console/course-order-submit', data)
}
export let uploadToken = (data) => {
    return request.post('/api/upload-token', data)
}
export let majorSubmit = (data) => {
    return request.post('/console/major-submit', data)
}
export let schoolSubmit = (data) => {
    return request.post('/console/school-type-submit', data)
}
export let schoolSubmitAdmin = (data) => {
    return request.post('/console/school-submit', data)
}
export let userSubmit = (data) => {
    return request.post('/console/user-submit', data)
}
export let packageSubmit = (data) => {
    return request.post('/console/course-package-submit', data)
}
export let timetableLeave = (data) => {
    return request.post('/console/timetable-leave', data)
}
export let timetableSubmit = (data) => {
    return request.post('/console/schedule-submit', data)
}
export let stockGoodsSubmit = (data) => {
    return request.post('/console/stock-goods-submit', data)
}
export let stockGoodsOutboundSubmit = (data) => {
    return request.post('/console/stock-goods-outbound-submit', data)
}
export let stockGoodsInboundSubmit = (data) => {
    return request.post('/console/stock-goods-inbound-submit', data)
}
export let goodsTypeSubmit = (data) => {
    return request.post('/console/stock-goods-type-submit', data)
}
export let courseSubmit = (data) => {
    return request.post('/console/course-submit', data)
}
export let schoolMoneySubmit = (data) => {
    return request.post('/console/school-money-submit', data)
}
export let timetableComplate = (data) => {
    return request.post('/console/timetable-complate', data)
}
export let schoolClassSubmit = (data) => {
    return request.post('/console/school-class-submit', data)
}
export let schoolKindSubmit = (data) => {
    return request.post('/console/school-money-kind-submit', data)
}
export let userRechargeSubmit = (data) => {
    return request.post('/console/user-recharge-submit', data)
}
export let courseOrderRefund = (data) => {
    return request.post('/console/course-order-refund', data)
}
export let majorList = (params) => {
    return request.get('/console/major-list', {
        params: params
    })
}
export let schoolList = (params) => {
    return request.get('/console/school-type-list', {
        params: params
    })
}
export let schoolListAdmin = (params) => {
    return request.get('/console/school-list', {
        params: params
    })
}
export let schoolUpdateStatus = (params) => {
    return request.get('/console/school-update-status', {
        params: params
    })
}
export let courseList = (params) => {
    return request.get('/console/course-list', {
        params: params
    })
}
export let packageList = (params) => {
    return request.get('/console/course-package-list', {
        params: params
    })
}
export let packageStatus = (params) => {
    return request.get('/console/course-package-update-status', {
        params: params
    })
}
export let schoolClassList = (params) => {
    return request.get('/console/school-class-list', {
        params: params
    })
}
export let userList = (params) => {
    return request.get('/console/user-list', {
        params: params
    })
}
export let userUpdateList = (params) => {
    return request.get('/console/user-update-status', {
        params: params
    })
}
export let sendSms = (params) => {
    return request.get('/console/send-sms', {
        params: params
    })
}
export let timetableList = (params) => {
    return request.get('/console/timetable-list', {
        params: params
    })
}
export let timetableStudent = (params) => {
    return request.get('/console/timetable-student', {
        params: params
    })
}
export let courseOrderList = (params) => {
    return request.get('/console/course-order-list', {
        params: params
    })
}
export let goodsTypeList = (params) => {
    return request.get('/console/stock-goods-type-list', {
        params: params
    })
}
export let schoolKinList = (params) => {
    return request.get('/console/school-money-kind-list', {
        params: params
    })
}
export let schoolGoodsList = (params) => {
    return request.get('/console/stock-goods-list', {
        params: params
    })
}
export let schoolGoodsStatus = (params) => {
    return request.get('/console/stock-goods-update-status', {
        params: params
    })
}
export let schoolGoodsDetail = (params) => {
    return request.get('/console/stock-goods-detail', {
        params: params
    })
}
export let schoolGoodsInboundList = (params) => {
    return request.get('/console/stock-goods-inbound-list', {
        params: params
    })
}
export let schoolGoodsOutboundList = (params) => {
    return request.get('/console/stock-goods-outbound-list', {
        params: params
    })
}
export let schoolGoodsOutboundDetail = (params) => {
    return request.get('/console/stock-goods-outbound-detail', {
        params: params
    })
}
export let schoolMoneyList = (params) => {
    return request.get('/console/school-money-list', {
        params: params
    })
}
export let userRechargeList = (params) => {
    return request.get('/console/user-recharge-list', {
        params: params
    })
}
