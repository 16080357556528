<template>
    <el-menu
        ref="menu"
        class="el-menu-vertical-main"
        background-color="transparent"
        :default-openeds="defaultOpeneds"
        :collapse="$store.state.common.sideMenuCollapsed"
        :router="true"
        :default-active="defaultActiveIndex">
        <template v-for="item in $store.state.user.menus">
            <el-menu-item v-if="!item.child" :index="item.menuPath || item.id + ''" :key="item.id">
                <template #title>
                    <div class="menu-list">
                        <img class="icons" :src="item.menuIcon" alt="" />
                        <span>{{ item.menuName }}</span>
                    </div>
                </template>
            </el-menu-item>
            <el-sub-menu v-else :index="item.id + ''" :key="item.id">
                <template #title>
                    <div class="menu-list">
                        <img class="icons" :src="item.menuIcon" alt="" />
                        <span>{{ item.menuName }}</span>
                    </div>
                </template>
                <el-menu-item v-for="child in item.child" :index="child.menuPath || item.id + '-' + child.id" :key="child.id">
                    <template #title>
                        <div class="menu-list2">
                            <img class="icons2" :src="child.menuIcon" alt="" />
                            <span>{{ child.menuName }}</span>
                        </div>
                    </template>
                </el-menu-item>
            </el-sub-menu>
        </template>
    </el-menu>
</template>
<script>
export default {
    name: 'SideMenus',
    watch: {
        '$route': {
            handler(v) {
                if (v && v.matched && v.matched.length > 0) {
                    this.$nextTick(() => {
                        this.defaultActiveIndex = v.matched[v.matched.length - 1].path
                    })
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            defaultActiveIndex: null,
            defaultOpeneds: ['9']
        }
    }
}
</script>
<style lang="css" scoped>
.menu-list{
    background-color: #fff;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    color: #6597FC;
    font-size: 20px;
}
.menu-list:after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: #409EFF;
}
.el-menu-vertical-main {
    min-height: calc(100vh - 210px);
    position: fixed;
    top: 210px;
    left: 0;
    z-index: 20;
}

.el-menu-vertical-main:not(.el-menu--collapse) {
    width: 300px;
}
.icons{
    width: 35px;
    height: 38px;
}
.menu-list2{
    font-size: 18px;
    color: #fff;
    box-sizing: border-box;
    padding-left: 20px;
}
.icons2{
    width: 18px;
    height: 18px;
    margin-right: 15px;
}
</style>
