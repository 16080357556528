export default {
  path: '/school',
  component: () => import(/* webpackChunkName: "systemIndex" */ '../../views/school/Index.vue'),
  meta: {
    humanName: '学校管理'
  },
  children: [{
    path: '/school/class',
    name: '班级管理',
    component: () => import('../../views/school/class/class.vue'),
    meta: { humanName: '班级管理' }
  },{
    path: '/school/student',
    name: '学生账号',
    component: () => import('../../views/school/student/student.vue'),
    meta: { humanName: '学生账号' }
  },{
    path: '/school/teacher',
    name: '教师账号',
    component: () => import('../../views/school/teacher/teacher.vue'),
    meta: { humanName: '教师账号' }
  },{
    path: '/school/schedule',
    name: '排课课表',
    component: () => import('../../views/school/schedule/schedule.vue'),
    meta: { humanName: '排课课表' }
  },{
    path: '/school/lesson',
    name: '课时缴费',
    component: () => import('../../views/school/lesson/lesson.vue'),
    meta: { humanName: '课时缴费' }
  },{
    path: '/school/recharge',
    name: '账户充值',
    component: () => import('../../views/school/recharge/recharge.vue'),
    meta: { humanName: '账户充值' }
  },{
    path: '/school/detail',
    name: '明细查询',
    component: () => import('../../views/school/detail/detail.vue'),
    meta: { humanName: '明细查询' }
  },{
    path: '/school/income',
    name: '收支查询',
    component: () => import('../../views/school/income/income.vue'),
    meta: { humanName: '收支查询' }
  },{
    path: '/school/product',
    name: '商品分类',
    component: () => import('../../views/school/product/product.vue'),
    meta: { humanName: '商品分类' }
  },{
    path: '/school/management',
    name: '商品管理',
    component: () => import('../../views/school/management/management.vue'),
    meta: { humanName: '商品管理' }
  },{
    path: '/school/warehousing',
    name: '入库管理',
    component: () => import('../../views/school/warehousing/warehousing.vue'),
    meta: { humanName: '入库管理' }
  },{
    path: '/school/outbound',
    name: '出库管理',
    component: () => import('../../views/school/outbound/outbound.vue'),
    meta: { humanName: '出库管理' }
  }]
}
